import { Modal } from "~/components/Modal";
import { ChatBubbleLeftIcon, CheckIcon } from "@heroicons/react/24/solid";
import React from "react";

export function FeedbackModal({
  show: showModal,
  userEmail,
  onClose,
}: {
  show: boolean;
  userEmail?: string;
  onClose: () => void;
}) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  async function handleSubmit(form: HTMLFormElement) {
    setLoading(true);
    const formData = new FormData(form);
    const response = await fetch(form.action, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });
    if (response.ok) {
      form.reset();
      setSuccess(true);
    }
    setLoading(false);
  }

  return (
    <>
      <Modal
        className="max-w-lg"
        allowClose={false}
        onClose={onClose}
        show={showModal}
      >
        <div>
          <div className="pb-4">
            Report issues, suggest features or give any general feedback. Thank
            you in advance!
          </div>
          <form
            className="mt-4"
            action="https://formspree.io/f/moqgqrwd"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e.target as HTMLFormElement);
            }}
            method="POST"
          >
            {userEmail ? (
              <input type="hidden" name="email" value={userEmail} />
            ) : (
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
            )}
            <input
              name="url"
              type="hidden"
              value={typeof window !== "undefined" ? window.location.href : ""}
            />
            <input
              name="subject"
              type="hidden"
              value="Feedback from {{ email }}"
            />
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Message
              </label>
              <textarea
                name="message"
                className="form-control"
                required
                placeholder="Describe your issue or suggestion here"
              ></textarea>
            </div>
            {success && (
              <div className="alert alert-info mb-4">
                Thank you! Your feedback has been submitted successfully.
              </div>
            )}
            <div className="flex items-center gap-x-2 pt-4">
              <button
                type="button"
                className="btn btn-neutral"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary flex items-center ml-auto"
              >
                {loading ? (
                  <>
                    <span className="spinner-icon-white w-4 h-4 mr-2"></span>
                    <span>Submitting</span>
                  </>
                ) : (
                  <>
                    <CheckIcon className="w-4 h-4 mr-2" />
                    <span>Submit</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export function FeedbackButton() {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <FeedbackModal show={showModal} onClose={() => setShowModal(false)} />
      <button
        className="z-40 fixed top-1/2 right-0 bg-primary-600 text-white py-2 px-4 origin-bottom-right -rotate-90 -translate-y-full rounded-t-md hide md:block flex"
        onClick={() => setShowModal(true)}
      >
        <ChatBubbleLeftIcon className="w-3 mr-1 inline-block" />
        <span>Feedback</span>
      </button>
    </>
  );
}
