import { XMarkIcon } from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

export interface ModalComponentProps {
  show?: boolean;
  className?: string;
  title?: string;
  allowClose?: boolean;
  onClose?: () => void;
  footer?: React.ReactNode | null;
  children: React.ReactNode | null;
}

export function Modal(props: ModalComponentProps) {
  const [show, setShow] = useState<boolean>(props.show ?? false);
  const allowClose = props.allowClose ?? true;

  useEffect(() => {
    if (props.show === undefined) {
      return;
    }
    setShow(props.show);
  }, [props.show]);

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-500 ease-in-out"
      enterFrom="opacity-0 -translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transition-opacity duration-500 ease-in-out"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-full"
    >
      <div
        className="dark:bg-black dark:bg-opacity-80 bg-black/80 fixed inset-0 z-50 py-[5vh] px-[5vw] overflow-scroll"
        onClick={(e) => {
          if (e.target === e.currentTarget && allowClose) {
            setShow(false);
            if (props.onClose) {
              props.onClose();
            }
          }
        }}
      >
        <div className={`flex items-center justify-center h-screen`}>
          <div
            className={`bg-white rounded-lg shadow-sm w-4/5 min-w-[300px] p-6 border border-gray-100 ${props.className}`}
          >
            <div className="flex justify-between items-center">
              {props.title && (
                <h3 className="m-0 text-lg font-bold pb-4">{props.title}</h3>
              )}
              {allowClose && (
                <button
                  onClick={() => {
                    setShow(false);
                    if (props.onClose) {
                      props.onClose();
                    }
                  }}
                  className="hover:cursor-pointer text-gray-300 pb-4 ml-auto hover:text-black"
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
              )}
            </div>
            <div>{props.children}</div>
            <div className="">{props.footer}</div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
